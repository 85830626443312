const header = document.querySelector('.ce_rsce_header');

if (header){
    document.addEventListener('scroll', function(){
        //console.log(window.pageYOffset)
        if (window.pageYOffset > 100){
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    })
}