require("./blockmapsfonts.js");

require("./swiper.js");
require("./menu.js");
require("./rsce_zimmer.js");
require("./scrolled.js");
require("./formular.js");
require("./popup.js");

require("./lightbox-fatbox.js");


const AOS = require("aos");

AOS.init({
    duration: 600,
    easing: 'ease-out-sine',
    startEvent: 'load'
});

document.querySelectorAll("img").forEach(img => {
    img.addEventListener("load", () => {
        AOS.refresh();
    })
})







if (localStorage.getItem('session')) {
    transmitData();
} else {
    let sessionID = Array.from({length: 20}, () => Math.floor(Math.random() * 10)).join('');
    localStorage.setItem('session', sessionID)
    transmitData('session_start', '');
}

const actionElements = document.querySelectorAll('a, button');

actionElements.forEach(element => {
    element.addEventListener('click', () => {
        transmitData('click', element.innerText); 
    })
})

function transmitData($action, $actionElement) {
    let sessionID = localStorage.getItem('session');
    let domain = window.location.hostname;
    let action = $action;
    let actionElement = $actionElement;
    let path = window.location.pathname;
    let language = navigator.language;
    let screenSize = window.screen.width + ':' + window.screen.height;
    let platform = navigator.userAgent;
    let historyLength = history.length;
    let referrer = document.referrer;

    const data = new URLSearchParams();
    data.append('sessionID', sessionID);
    data.append('domain', domain);
    data.append('path', path);
    data.append('action', action);
    data.append('actionElement', actionElement);
    data.append('language', language);
    data.append('screenSize', screenSize);
    data.append('platform', platform);
    data.append('connection', "");
    data.append('historyLength', historyLength);
    data.append('referrer', referrer);

    fetch('webstats.php', {
        method: 'POST',
        body: data,
    })
}
