const popup = document.querySelectorAll('.ce_rsce_popup');
const body = document.querySelector('body');

if (popup.length > 0){
    checkpopup();
    popup.forEach(popup =>{
        let closeButton = popup.querySelector('.close');
        let linkButton = popup.querySelector('.button');
        closeButton.addEventListener('click', function(){
            popup.style.display = 'none';
            localStorage.setItem('popup', 'false')
        })
        body.addEventListener('click', function(){
            popup.style.display = 'none';
            localStorage.setItem('popup', 'false')
        })
        linkButton.addEventListener('click', function(){
            popup.style.display = 'none';
            localStorage.setItem('popup', 'false')
        })
    });
}

function checkpopup(){
    if (localStorage.getItem('popup')){
        if (window.location.pathname === '/'){
            popup.forEach(popup => {
                popup.style.display = 'block';
            })
        } else {
            popup.forEach(popup => {
                popup.style.display = 'none';
            })
        }
        

    } else {
        popup.forEach(popup => {
            popup.style.display = 'block';
        })
    }
}